// extracted by mini-css-extract-plugin
export var root = "PlasmicBlogPost-module--root--04YPU";
export var root__long = "PlasmicBlogPost-module--root__long--ZBDEi";
export var freeBox__xPEg6 = "PlasmicBlogPost-module--freeBox__xPEg6--dj+CF";
export var freeBox__long__xPEg6E5KUc = "PlasmicBlogPost-module--freeBox__long__xPEg6E5KUc--uf-+l";
export var img__zpJ8Z = "PlasmicBlogPost-module--img__zpJ8Z--g5I1i";
export var __wab_imgSpacer = "PlasmicBlogPost-module--__wab_img-spacer--o723R";
export var freeBox__roYrt = "PlasmicBlogPost-module--freeBox__roYrt--EDXIG";
export var freeBox__long__roYrtE5KUc = "PlasmicBlogPost-module--freeBox__long__roYrtE5KUc--+2hEo";
export var slotTargetTitle = "PlasmicBlogPost-module--slotTargetTitle--nrGD3";
export var slotTargetAuthor = "PlasmicBlogPost-module--slotTargetAuthor--XRmpo";
export var slotTargetDescription = "PlasmicBlogPost-module--slotTargetDescription---FN8C";
export var slotTargetDescription__long = "PlasmicBlogPost-module--slotTargetDescription__long--sLK95";