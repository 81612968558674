// extracted by mini-css-extract-plugin
export var root = "PlasmicProductCarousel-module--root--R16-P";
export var freeBox__g6Pcn = "PlasmicProductCarousel-module--freeBox__g6Pcn--j4EkU";
export var freeBox___0ErKu = "PlasmicProductCarousel-module--freeBox___0ErKu--rCeGy";
export var text__dbLdP = "PlasmicProductCarousel-module--text__dbLdP---UhB3";
export var text__c1WOo = "PlasmicProductCarousel-module--text__c1WOo--ztmmG";
export var text__rwVua = "PlasmicProductCarousel-module--text__rwVua--Ew7DD";
export var freeBox__iwCH = "PlasmicProductCarousel-module--freeBox__iwCH--o7FdH";
export var freeBox__t3Nzm = "PlasmicProductCarousel-module--freeBox__t3Nzm--JlW7Z";
export var text__x72Xq = "PlasmicProductCarousel-module--text__x72Xq--AhaU4";
export var text__le6Vg = "PlasmicProductCarousel-module--text__le6Vg--BImt6";
export var freeBox__uNcXq = "PlasmicProductCarousel-module--freeBox__uNcXq--ICrWI";
export var linkButton__zFYmW = "PlasmicProductCarousel-module--linkButton__zFYmW--RErLZ";
export var linkButton__weknO = "PlasmicProductCarousel-module--linkButton__weknO--0rnpP";
export var freeBox__lu9P4 = "PlasmicProductCarousel-module--freeBox__lu9P4--m5jl8";
export var img = "PlasmicProductCarousel-module--img--RWUCC";
export var __wab_imgSpacer = "PlasmicProductCarousel-module--__wab_img-spacer--v+AnL";
export var freeBox___8ClPc = "PlasmicProductCarousel-module--freeBox___8ClPc--4SCGZ";
export var svg__lYHj9 = "PlasmicProductCarousel-module--svg__lYHj9--DeAOS";
export var svg__rVg0Q = "PlasmicProductCarousel-module--svg__rVg0Q--Myh4q";
export var svg__nxYk9 = "PlasmicProductCarousel-module--svg__nxYk9--jPfXD";