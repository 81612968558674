// extracted by mini-css-extract-plugin
export var root = "PlasmicProductCard-module--root--68k6O";
export var freeBox__d0Elq = "PlasmicProductCard-module--freeBox__d0Elq--6mbUu";
export var img__f8QUr = "PlasmicProductCard-module--img__f8QUr--Y4nG7";
export var __wab_imgSpacer = "PlasmicProductCard-module--__wab_img-spacer--nJXuB";
export var freeBox___5JpEo = "PlasmicProductCard-module--freeBox___5JpEo--SBqYi";
export var text__crPq5 = "PlasmicProductCard-module--text__crPq5--te6kh";
export var freeBox__zyfib = "PlasmicProductCard-module--freeBox__zyfib--xIHQ5";
export var svg__kPb1R = "PlasmicProductCard-module--svg__kPb1R--n+oJk";
export var text__gixV = "PlasmicProductCard-module--text__gixV--gp206";
export var svg__qdbKm = "PlasmicProductCard-module--svg__qdbKm--mqnzX";
export var svg__gpb33 = "PlasmicProductCard-module--svg__gpb33--STD73";
export var ratings__cfWxc = "PlasmicProductCard-module--ratings__cfWxc--9XxAv";
export var freeBox__j8BA5 = "PlasmicProductCard-module--freeBox__j8BA5--zgvfB";
export var slotTargetTitle = "PlasmicProductCard-module--slotTargetTitle--EQtbD";
export var slotTargetPrice = "PlasmicProductCard-module--slotTargetPrice--yQtC2";