// extracted by mini-css-extract-plugin
export var root = "PlasmicFeatureItem-module--root---D9xd";
export var freeBox__zvmVw = "PlasmicFeatureItem-module--freeBox__zvmVw--wYrlu";
export var freeBox__hnjTg = "PlasmicFeatureItem-module--freeBox__hnjTg--lbW7N";
export var freeBox__oZosK = "PlasmicFeatureItem-module--freeBox__oZosK--XF57h";
export var freeBox___5CiZw = "PlasmicFeatureItem-module--freeBox___5CiZw--YCbQM";
export var text__nR96 = "PlasmicFeatureItem-module--text__nR96--jMF7q";
export var text___80Blo = "PlasmicFeatureItem-module--text___80Blo--0-nIj";
export var freeBox__cp1ZR = "PlasmicFeatureItem-module--freeBox__cp1ZR--fZ27r";
export var text__dlr5N = "PlasmicFeatureItem-module--text__dlr5N--D+-jw";
export var text__fl0Tk = "PlasmicFeatureItem-module--text__fl0Tk--VbCcd";
export var freeBox__idug = "PlasmicFeatureItem-module--freeBox__idug--jOwTe";
export var linkButton = "PlasmicFeatureItem-module--linkButton--9q5Kg";