// extracted by mini-css-extract-plugin
export var root = "PlasmicFooter-module--root--k30V+";
export var freeBox__y3RzK = "PlasmicFooter-module--freeBox__y3RzK--tl6tv";
export var freeBox__tEnGx = "PlasmicFooter-module--freeBox__tEnGx---qKQS";
export var text__zZboQ = "PlasmicFooter-module--text__zZboQ--1vZVQ";
export var freeBox__vuT70 = "PlasmicFooter-module--freeBox__vuT70--nyi-U";
export var link__dhezn = "PlasmicFooter-module--link__dhezn--8-WlQ";
export var link__ouIk1 = "PlasmicFooter-module--link__ouIk1--vzUMI";
export var link___4J2Xh = "PlasmicFooter-module--link___4J2Xh--MrVu4";
export var link__tgOyI = "PlasmicFooter-module--link__tgOyI--fZreH";
export var freeBox__bMhg = "PlasmicFooter-module--freeBox__bMhg--4ocSg";
export var text__hc7HI = "PlasmicFooter-module--text__hc7HI--4o1Y3";
export var freeBox__fufv9 = "PlasmicFooter-module--freeBox__fufv9--+QXY4";
export var link__zFa7Y = "PlasmicFooter-module--link__zFa7Y--bS+-4";
export var link__ujLZ = "PlasmicFooter-module--link__ujLZ--dWEQR";
export var link__mUblZ = "PlasmicFooter-module--link__mUblZ--hY7TC";
export var link__vOlQe = "PlasmicFooter-module--link__vOlQe--hs-HL";
export var link__vzc8D = "PlasmicFooter-module--link__vzc8D--JxY4Z";
export var freeBox__ypJn = "PlasmicFooter-module--freeBox__ypJn--gcUNz";
export var text__aqm7 = "PlasmicFooter-module--text__aqm7--L-iAP";
export var freeBox__fd2Jv = "PlasmicFooter-module--freeBox__fd2Jv--IA9c9";
export var link___7C3ET = "PlasmicFooter-module--link___7C3ET--abSUW";
export var link__iAbFi = "PlasmicFooter-module--link__iAbFi--lq+hb";
export var link__duvsG = "PlasmicFooter-module--link__duvsG--GuMPS";
export var link__mP6Mc = "PlasmicFooter-module--link__mP6Mc--toG5T";
export var link__xBjmq = "PlasmicFooter-module--link__xBjmq--fBg6P";
export var freeBox__ouNIq = "PlasmicFooter-module--freeBox__ouNIq--wHGaX";
export var text__vflf1 = "PlasmicFooter-module--text__vflf1--h670N";
export var text__snIzG = "PlasmicFooter-module--text__snIzG--xrz6L";
export var freeBox__ffdVv = "PlasmicFooter-module--freeBox__ffdVv--X-IrE";
export var textInput = "PlasmicFooter-module--textInput--XiD1c";
export var button = "PlasmicFooter-module--button--dYS4+";
export var svg__t6Nb = "PlasmicFooter-module--svg__t6Nb--YK9xG";
export var freeBox__jm2Vi = "PlasmicFooter-module--freeBox__jm2Vi--MLoxM";
export var freeBox___3Gqp9 = "PlasmicFooter-module--freeBox___3Gqp9--JSVuU";
export var iconLink__mNwc = "PlasmicFooter-module--iconLink__mNwc--g8atw";
export var svg___24BR = "PlasmicFooter-module--svg___24BR--yPfpX";
export var iconLink__k2L9A = "PlasmicFooter-module--iconLink__k2L9A--okMFd";
export var svg__dWDsX = "PlasmicFooter-module--svg__dWDsX--uZZSR";
export var iconLink___4BjPu = "PlasmicFooter-module--iconLink___4BjPu--o77fB";
export var svg__qa1Id = "PlasmicFooter-module--svg__qa1Id--BaNQi";
export var freeBox__qkY7 = "PlasmicFooter-module--freeBox__qkY7--J0Tqu";
export var text__cI1Kb = "PlasmicFooter-module--text__cI1Kb--yJAfU";
export var link__qkKkl = "PlasmicFooter-module--link__qkKkl--owO4v";
export var link__pdFDm = "PlasmicFooter-module--link__pdFDm--5V9uB";