// extracted by mini-css-extract-plugin
export var root = "PlasmicRatings-module--root--4qVvy";
export var freeBox = "PlasmicRatings-module--freeBox--x5RPI";
export var svg__bspf = "PlasmicRatings-module--svg__bspf--fsoab";
export var svg__stars_zero__bspfRiyNc = "PlasmicRatings-module--svg__stars_zero__bspfRiyNc--4-5Oy";
export var svg___9SnEl = "PlasmicRatings-module--svg___9SnEl--HkCo-";
export var svg__stars_zero___9SnELriyNc = "PlasmicRatings-module--svg__stars_zero___9SnELriyNc--oYOpi";
export var svg__stars_one___9SnELtUtfy = "PlasmicRatings-module--svg__stars_one___9SnELtUtfy--NRcKm";
export var svg__lB12Y = "PlasmicRatings-module--svg__lB12Y--sWkfG";
export var svg__stars_zero__lB12YriyNc = "PlasmicRatings-module--svg__stars_zero__lB12YriyNc--xsjhX";
export var svg__stars_one__lB12YtUtfy = "PlasmicRatings-module--svg__stars_one__lB12YtUtfy--zEPj0";
export var svg__stars_two__lB12Y9Wk0N = "PlasmicRatings-module--svg__stars_two__lB12Y9Wk0N--U3h4x";
export var svg___7M3Ja = "PlasmicRatings-module--svg___7M3Ja--kDVbv";
export var svg__stars_zero___7M3JariyNc = "PlasmicRatings-module--svg__stars_zero___7M3JariyNc--SF86B";
export var svg__stars_one___7M3JatUtfy = "PlasmicRatings-module--svg__stars_one___7M3JatUtfy--uwl9a";
export var svg__stars_two___7M3Ja9Wk0N = "PlasmicRatings-module--svg__stars_two___7M3Ja9Wk0N--oKpwm";
export var svg__stars_three___7M3JagQqka = "PlasmicRatings-module--svg__stars_three___7M3JagQqka--1-aCx";
export var svg__eMmbY = "PlasmicRatings-module--svg__eMmbY--tDMnq";
export var svg__stars_zero__eMmbYriyNc = "PlasmicRatings-module--svg__stars_zero__eMmbYriyNc--Cj2Ae";
export var svg__stars_one__eMmbYtUtfy = "PlasmicRatings-module--svg__stars_one__eMmbYtUtfy--YZy1b";
export var svg__stars_two__eMmbY9Wk0N = "PlasmicRatings-module--svg__stars_two__eMmbY9Wk0N--LKUEy";
export var svg__stars_three__eMmbYgQqka = "PlasmicRatings-module--svg__stars_three__eMmbYgQqka--9qhd9";
export var svg__stars_four__eMmbYXtwsv = "PlasmicRatings-module--svg__stars_four__eMmbYXtwsv--GEYW1";
export var svg__dxRod = "PlasmicRatings-module--svg__dxRod--1fJ7b";
export var svg__stars_one__dxRodtUtfy = "PlasmicRatings-module--svg__stars_one__dxRodtUtfy--HNFAn";
export var svg__stars_two__dxRod9Wk0N = "PlasmicRatings-module--svg__stars_two__dxRod9Wk0N--LE-kj";
export var svg__stars_three__dxRodgQqka = "PlasmicRatings-module--svg__stars_three__dxRodgQqka--xtxLH";
export var svg__stars_four__dxRodXtwsv = "PlasmicRatings-module--svg__stars_four__dxRodXtwsv--Sfy7Q";
export var svg__stars_five__dxRodYtWGw = "PlasmicRatings-module--svg__stars_five__dxRodYtWGw--LxfMA";
export var svg__f60V = "PlasmicRatings-module--svg__f60V--8p38I";
export var svg__stars_two__f60V9Wk0N = "PlasmicRatings-module--svg__stars_two__f60V9Wk0N--QbWfk";
export var svg__stars_three__f60VgQqka = "PlasmicRatings-module--svg__stars_three__f60VgQqka--B57ka";
export var svg__stars_four__f60VXtwsv = "PlasmicRatings-module--svg__stars_four__f60VXtwsv--Gccx+";
export var svg__stars_five__f60VYtWGw = "PlasmicRatings-module--svg__stars_five__f60VYtWGw--ny84Q";
export var svg__cpGJ = "PlasmicRatings-module--svg__cpGJ--p9wvt";
export var svg__stars_three__cpGJgQqka = "PlasmicRatings-module--svg__stars_three__cpGJgQqka--OxH+q";
export var svg__stars_four__cpGJXtwsv = "PlasmicRatings-module--svg__stars_four__cpGJXtwsv--fb+kn";
export var svg__stars_five__cpGJYtWGw = "PlasmicRatings-module--svg__stars_five__cpGJYtWGw--SPZW8";
export var svg__tSWhR = "PlasmicRatings-module--svg__tSWhR--Ao7WO";
export var svg__stars_four__tSWhRXtwsv = "PlasmicRatings-module--svg__stars_four__tSWhRXtwsv--DmTpK";
export var svg__stars_five__tSWhRYtWGw = "PlasmicRatings-module--svg__stars_five__tSWhRYtWGw--MyXGB";
export var svg__fVz = "PlasmicRatings-module--svg__fVz--o1fA6";
export var svg__stars_five__fVzYtWGw = "PlasmicRatings-module--svg__stars_five__fVzYtWGw--YWchb";
export var slotTargetNumReviews = "PlasmicRatings-module--slotTargetNumReviews--S7tCy";