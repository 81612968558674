// extracted by mini-css-extract-plugin
export var root = "PlasmicLinkButton-module--root--tcMeU";
export var root__type_solidOrange = "PlasmicLinkButton-module--root__type_solidOrange--dw0ZK";
export var root__type_solidGray = "PlasmicLinkButton-module--root__type_solidGray--3xle+";
export var root__type_outlineGray = "PlasmicLinkButton-module--root__type_outlineGray--1lOjh";
export var root__size_small = "PlasmicLinkButton-module--root__size_small--EYhBU";
export var root__type_blankOrange = "PlasmicLinkButton-module--root__type_blankOrange--dFfId";
export var slotTargetText = "PlasmicLinkButton-module--slotTargetText--VK7Xl";
export var slotTargetText__type_solidOrange = "PlasmicLinkButton-module--slotTargetText__type_solidOrange--4ZmiO";
export var slotTargetText__type_solidGray = "PlasmicLinkButton-module--slotTargetText__type_solidGray--53e-E";
export var slotTargetText__type_blankOrange = "PlasmicLinkButton-module--slotTargetText__type_blankOrange--mp7Zw";
export var slotTargetText__type_outlineGray = "PlasmicLinkButton-module--slotTargetText__type_outlineGray--k2xad";
export var slotTargetText__type_blankGray = "PlasmicLinkButton-module--slotTargetText__type_blankGray--leY-h";
export var root__type_blankGray = "PlasmicLinkButton-module--root__type_blankGray--mm+z1";