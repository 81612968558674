// extracted by mini-css-extract-plugin
export var all = "plasmic__default_style-module--all--yEtu5";
export var img = "plasmic__default_style-module--img--pZ4La plasmic__default_style-module--all--yEtu5";
export var span = "plasmic__default_style-module--span--7Dt6m plasmic__default_style-module--all--yEtu5";
export var input = "plasmic__default_style-module--input--FosFZ plasmic__default_style-module--all--yEtu5";
export var textarea = "plasmic__default_style-module--textarea--6hh-u plasmic__default_style-module--all--yEtu5";
export var button = "plasmic__default_style-module--button--LbYJQ plasmic__default_style-module--all--yEtu5";
export var code = "plasmic__default_style-module--code--H2F55 plasmic__default_style-module--all--yEtu5";
export var pre = "plasmic__default_style-module--pre--K7wKQ plasmic__default_style-module--all--yEtu5";
export var p = "plasmic__default_style-module--p--mgzt- plasmic__default_style-module--all--yEtu5";
export var h1 = "plasmic__default_style-module--h1--R5whm plasmic__default_style-module--all--yEtu5";
export var h2 = "plasmic__default_style-module--h2--1Ef3z plasmic__default_style-module--all--yEtu5";
export var h3 = "plasmic__default_style-module--h3--zj095 plasmic__default_style-module--all--yEtu5";
export var h4 = "plasmic__default_style-module--h4--WhoxO plasmic__default_style-module--all--yEtu5";
export var h5 = "plasmic__default_style-module--h5--36YWk plasmic__default_style-module--all--yEtu5";
export var h6 = "plasmic__default_style-module--h6--+k9-m plasmic__default_style-module--all--yEtu5";
export var address = "plasmic__default_style-module--address--anOQ6 plasmic__default_style-module--all--yEtu5";
export var a = "plasmic__default_style-module--a--gUCFX plasmic__default_style-module--all--yEtu5";
export var ol = "plasmic__default_style-module--ol--tNc3s plasmic__default_style-module--all--yEtu5";
export var ul = "plasmic__default_style-module--ul--NjhIW plasmic__default_style-module--all--yEtu5";
export var select = "plasmic__default_style-module--select--vwLzV plasmic__default_style-module--all--yEtu5";
export var li = "plasmic__default_style-module--li--idWWf plasmic__default_style-module--all--yEtu5";
export var plasmic_default__component_wrapper = "plasmic__default_style-module--plasmic_default__component_wrapper--a-lIW";
export var plasmic_default__inline = "plasmic__default_style-module--plasmic_default__inline--OcnEC";
export var plasmic_page_wrapper = "plasmic__default_style-module--plasmic_page_wrapper--sMEb-";